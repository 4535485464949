<template>
  <div
    class="subheader py-2 py-lg-4"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap container"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!-- <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ title }}
        </h5> -->
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <!-- <li class="breadcrumb-item">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li> -->

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span
                class="text-muted orange-color"
                :key="i"
                v-if="!breadcrumb.route"
              >
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <!-- Actions Start -->
      <!-- <div class="d&#45;flex align&#45;items&#45;center"> -->
      <!--   <a href="#" class="btn btn&#45;light font&#45;weight&#45;bold btn&#45;sm"> -->
      <!--     Actions -->
      <!--   </a> -->
      <!--  -->
      <!--   <b&#45;dropdown -->
      <!--     size="sm" -->
      <!--     variant="link" -->
      <!--     toggle&#45;class="custom&#45;v&#45;dropdown" -->
      <!--     no&#45;caret -->
      <!--     right -->
      <!--     no&#45;flip -->
      <!--     text="Actions" -->
      <!--     v&#45;b&#45;tooltip.hover="'Quick actions'" -->
      <!--   > -->
      <!--     <template v&#45;slot:button&#45;content> -->
      <!--       <a href="#" class="btn btn&#45;icon" data&#45;toggle="dropdown"> -->
      <!--         <span class="svg&#45;icon svg&#45;icon&#45;success svg&#45;icon&#45;2x"> -->
      <!--           <!&#45;&#45;begin::Svg Icon&#45;&#45;> -->
      <!--           <inline&#45;svg src="media/svg/icons/Files/File&#45;plus.svg" /> -->
      <!--           <!&#45;&#45;end::Svg Icon&#45;&#45;> -->
      <!--         </span> -->
      <!--       </a> -->
      <!--     </template> -->
      <!--     <!&#45;&#45;begin::Navigation&#45;&#45;> -->
      <!--     <div class="navi navi&#45;hover min&#45;w&#45;md&#45;250px"> -->
      <!--       <b&#45;dropdown&#45;text tag="div" class="navi&#45;header font&#45;weight&#45;bold"> -->
      <!--         Jump to: -->
      <!--         <i -->
      <!--           class="flaticon2&#45;information" -->
      <!--           data&#45;toggle="tooltip" -->
      <!--           data&#45;placement="left" -->
      <!--           v&#45;b&#45;tooltip.hover -->
      <!--           title="Click to learn more..." -->
      <!--         /> -->
      <!--       </b&#45;dropdown&#45;text> -->
      <!--       <b&#45;dropdown&#45;text -->
      <!--         tag="div" -->
      <!--         class="navi&#45;separator mb&#45;3" -->
      <!--       ></b&#45;dropdown&#45;text> -->
      <!--       <b&#45;dropdown&#45;text tag="div" class="navi&#45;item"> -->
      <!--         <a href="#" class="navi&#45;link"> -->
      <!--           <span class="navi&#45;icon"> -->
      <!--             <i class="flaticon2&#45;drop"></i> -->
      <!--           </span> -->
      <!--           <span class="navi&#45;text">Recent Orders</span> -->
      <!--         </a> -->
      <!--       </b&#45;dropdown&#45;text> -->
      <!--       <b&#45;dropdown&#45;text tag="div" class="navi&#45;item"> -->
      <!--         <a href="#" class="navi&#45;link"> -->
      <!--           <span class="navi&#45;icon"> -->
      <!--             <i class="flaticon2&#45;calendar&#45;8"></i> -->
      <!--           </span> -->
      <!--           <span class="navi&#45;text">Support Cases</span> -->
      <!--         </a> -->
      <!--       </b&#45;dropdown&#45;text> -->
      <!--       <b&#45;dropdown&#45;text tag="div" class="navi&#45;item"> -->
      <!--         <a href="#" class="navi&#45;link"> -->
      <!--           <span class="navi&#45;icon"> -->
      <!--             <i class="flaticon2&#45;telegram&#45;logo"></i> -->
      <!--           </span> -->
      <!--           <span class="navi&#45;text">Projects</span> -->
      <!--         </a> -->
      <!--       </b&#45;dropdown&#45;text> -->
      <!--       <b&#45;dropdown&#45;text tag="div" class="navi&#45;item"> -->
      <!--         <a href="#" class="navi&#45;link"> -->
      <!--           <span class="navi&#45;icon"> -->
      <!--             <i class="flaticon2&#45;new&#45;email"></i> -->
      <!--           </span> -->
      <!--           <span class="navi&#45;text">Messages</span> -->
      <!--           <span class="navi&#45;label"> -->
      <!--             <span class="label label&#45;success label&#45;rounded">5</span> -->
      <!--           </span> -->
      <!--         </a> -->
      <!--       </b&#45;dropdown&#45;text> -->
      <!--       <b&#45;dropdown&#45;text -->
      <!--         tag="div" -->
      <!--         class="navi&#45;separator mt&#45;3" -->
      <!--       ></b&#45;dropdown&#45;text> -->
      <!--       <b&#45;dropdown&#45;text tag="div" class="navi&#45;footer"> -->
      <!--         <a -->
      <!--           class="btn btn&#45;light&#45;primary font&#45;weight&#45;bolder btn&#45;sm" -->
      <!--           href="#" -->
      <!--           >Upgrade plan</a -->
      <!--         > -->
      <!--         <a -->
      <!--           class="btn btn&#45;clean font&#45;weight&#45;bold btn&#45;sm" -->
      <!--           href="#" -->
      <!--           data&#45;toggle="tooltip" -->
      <!--           data&#45;placement="left" -->
      <!--           v&#45;b&#45;tooltip.hover -->
      <!--           title="Click to learn more..." -->
      <!--           >Learn more</a -->
      <!--         > -->
      <!--       </b&#45;dropdown&#45;text> -->
      <!--     </div> -->
      <!--     <!&#45;&#45;end::Navigation&#45;&#45;> -->
      <!--   </b&#45;dropdown> -->
      <!-- </div> -->
      <!-- Actions End -->
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    }
  }
};
</script>
